import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { OrganisationListInterface, countryInterface } from '../../../modules/accounts/components/settings/SettingsModel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get_all_countries } from '../../../modules/auth/core/_requests';
import { toast } from 'react-toastify';
import { get_organisation_data, update_organisation } from '../api/OrganisationsApi';
import { Form } from 'react-bootstrap';
import "../../../../_metronic/assets/keenicons/duotone/style.css";
import { config_file_size } from '../../../../_metronic/helpers/config/config';
import { validateImage } from '../../../helper_functions/ImageValidation';
function EditOrganisation() {
    const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationListInterface | null>();
    const [countriesList, setCountriesList] = useState<countryInterface[]>([]);
    // const [applicationList, setApplicationList] = useState<applicationsInterface[]>([]);
    const [whiteLogo, setWhiteLogo] = useState<File | null>(null);
    const [colorLogo, setColorLogo] = useState<File | null>(null);
    const [organisationIcon, setOrganisationIcon] = useState<File | null>(null);
    const [nameError, setNameError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const ref = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const organisation = (location.state as { organisation: OrganisationListInterface })?.organisation;
    const { data, isLoading: organisationDataIsLoading, error } = useQuery(
        ['organisationData'],
        () => (organisation?.id ? get_organisation_data(organisation.id) : Promise.reject("Organisation ID is missing")),
        {
          refetchOnWindowFocus: false,
          staleTime: 0, // Data will always be treated as stale
        }
      );    
      useEffect(() => {
        if (organisationDataIsLoading) {
          setIsLoading(true); // Start loading when query begins
        } else {
          // Ensure the loading spinner stays visible for at least 3 seconds
          const timer = setTimeout(() => {
            setIsLoading(false);
          },3000);
      
          return () => clearTimeout(timer); // Clean up timeout if the component unmounts or the query restarts
        }
      }, [organisationDataIsLoading]);      
    useEffect(() => {
        if(data){
            setSelectedOrganisation(data.data);
        }
    }, [data]);
    const { data: countryData } = useQuery('get_all_countries', get_all_countries, { refetchOnWindowFocus: false });
    useEffect(() => {
        if (countryData) {
            setCountriesList(countryData.data as countryInterface[]);
        }
    }, [countryData]);
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newName = e.target.value;
            if (!newName) {
                setNameError('Organisation Name is required.');
            } else {
                setNameError('');
            };
            setSelectedOrganisation({
                ...selectedOrganisation,
                name: newName,
            });
        };
    };
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newEmail = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                email: newEmail,
            });
        };

    };
    
    const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newPhoneNumber = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                phone: newPhoneNumber,
            });
        }
    };

    const handleMobileNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newMobile = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                mobile: newMobile,
            });
        };
    };
    const handleAddress1Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newAddress1 = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                address_1: newAddress1,
            });
        };
    };
    const handleAddress2Change = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newAddress2 = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                address_2: newAddress2,
            });
        };
    };
    const handleTownChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newTown = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                town: newTown,
            });
        };
    };
    const handleCityChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newCity = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                city: newCity,
            });
        };
    };
    const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (selectedOrganisation) {
            const newCountry =e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                country: newCountry,
            });
        };
    };
    const handlePostcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (selectedOrganisation) {
            const newPostcode = e.target.value;
            setSelectedOrganisation({
                ...selectedOrganisation,
                post_code: newPostcode,
            });
        };
    };
const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    logoType:string,
    MAX_FILE_SIZE: number,
    ALLOWED_TYPES: string[],
    MAX_DIMENSIONS: { [key: string]: { width: number; height: number } }
  ) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      // Call the validation function
      validateImage(
        newFile,
        logoType,
        (validFileUrl) => {
          // If valid, update the state with the image URL
          if (logoType === 'color_logo') {
            setColorLogo(newFile);
          } else if (logoType === 'white_logo') {
            setWhiteLogo(newFile);
          } else if (logoType === 'favicon') {
            setOrganisationIcon(newFile);
          }
        },
        MAX_FILE_SIZE, // File size limit in MB
        ALLOWED_TYPES, // Allowed file types
        MAX_DIMENSIONS // Maximum dimensions per logo type
      );
    }
  };
    const handleUpdateOrganisation = () => {
        try {
            setLoading(true);
            if (!selectedOrganisation) {
                throw new Error('No Organisation Selected for Update');
            }
          // Helper function to ensure no null values
          const sanitizeValue = (value: any) => (value == null ? '' : value);
            const formData = new FormData();
            formData.append('name', sanitizeValue(selectedOrganisation.name));
            formData.append('email', sanitizeValue(selectedOrganisation.email));
            formData.append('phone', sanitizeValue(selectedOrganisation.phone));
            formData.append('mobile', sanitizeValue(selectedOrganisation.mobile));
            formData.append('address_1', sanitizeValue(selectedOrganisation.address_1));
            formData.append('address_2', sanitizeValue(selectedOrganisation.address_2));
            formData.append('town', sanitizeValue(selectedOrganisation.town));
            formData.append('city', sanitizeValue(selectedOrganisation.city));
            formData.append('country', sanitizeValue(selectedOrganisation.country));
            formData.append('post_code', sanitizeValue(selectedOrganisation.post_code));
            formData.append('status', sanitizeValue(selectedOrganisation.status?.toString()));
            // Append files if they exist
            if (colorLogo) {
                formData.append('color_logo', colorLogo, colorLogo.name);
            }
            if (whiteLogo) {
                formData.append('white_logo', whiteLogo, whiteLogo.name);
            }
            if (organisationIcon) {
                formData.append('favicon', organisationIcon, organisationIcon.name);
            }
    
            // Log formData to verify
            formData.forEach((value, key) => {
                console.log(key, value);
            });
    
            updateOrganisationMutation.mutate({
                id: selectedOrganisation.id,
                formData,
            });
        } catch (error: any) {
            toast.error(error.message || 'An error occurred.', {
                position: 'top-right',
                autoClose: 3000,
            });
            setLoading(false);
        }
    };
    const updateOrganisationMutation = useMutation((Data: any) => update_organisation(Data.id, Data.formData), {
        onSuccess: (response) => {
            //   refetch();
            setLoading(false);
            navigate('/organisations/organisations-list');
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
            ref.current && (ref.current.value = '')
        },
        onError: (error: any) => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
                setLoading(false);
            } else if (error.request) {
                // The request was made but no response was received
                toast.error('No response received from the server.', {
                    position: "top-right",
                    autoClose: 3000,
                });
                setLoading(false);
            } else {
                toast.error('An unexpected error occurred.', {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
            setLoading(false);
        },
    });
    if (isLoading) {
        return (
          <div className='text-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )
      }
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
            >
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Edit Organisation</span>
                </h3>
                <div
                    className='card-toolbar'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List of Organisation"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/organisations')}
                    >
                        List of Organisations
                    </button>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
              <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="currentColor"></path><path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="currentColor"></path></svg>
              </span>
              <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                <h5 className="mb-1">Note!</h5>
                <span>The changes in the settings will be effected on next login.</span>
              </div>
              <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                <span className="svg-icon svg-icon-2x svg-icon-light">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg>
                </span>
              </button>
            </div>
                    {selectedOrganisation ? (
                        <>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    <span>Organisation Name</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Organisation Name'
                                        value={selectedOrganisation ? selectedOrganisation.name : ''}
                                        onChange={(e) => handleNameChange(e)}
                                    />
                                    {nameError && (
                                        <div className='text-danger'>{nameError}</div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span>Organisation Email</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Organisation Email'
                                        value={selectedOrganisation.email || ''}
                                        onChange={(e) => {
                                            handleEmailChange(e);
                                        }}
                            
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone Number</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Phone Number'
                                        value={selectedOrganisation.phone||''}
                                        onChange={(e) => handlePhoneChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    Mobile Number
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Mobile number'
                                        value={selectedOrganisation.mobile || ''}
                                        onChange={(e) => handleMobileNumberChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6 '>Address Line 1</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Address Line 1'
                                        value={selectedOrganisation.address_1 ||''}
                                        onChange={(e) => handleAddress1Change(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Address Line 2</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Address Line 2'
                                        value={selectedOrganisation.address_2||''}
                                        onChange={(e) => handleAddress2Change(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Town</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Town'
                                        value={selectedOrganisation.town || ''}
                                        onChange={(e) => handleTownChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span>Country</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <select
                                        className='form-select form-select-solid form-select-lg fw-bold'
                                        value={selectedOrganisation?.country || ''}
                                        onChange={(e) => handleCountryChange(e)}
                                    >
                                        <option value=''>Select a Country...</option>
                                        {Array.isArray(countriesList) && countriesList.length > 0 ? (
                                            countriesList.map((country) => (
                                                <option key={country.id} value={country.country_name}>
                                                    {country.country_name}
                                                </option>
                                            ))
                                        ) : (
                                            <option value='' disabled>No Countries available</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>City</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='City'
                                        value={selectedOrganisation.city || ''}
                                        onChange={(e) => handleCityChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Postcode</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='tel'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Postcode'
                                        value={selectedOrganisation.post_code || ''}
                                        onChange={(e) => handlePostcodeChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Favicon</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='file'
                                        className='form-control form-control-lg form-control-solid'
                                        onChange={(e) => handleImageChange(
                                            e,
                                            'favicon',
                                            2, // Max file size (2MB)
                                            ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                                            {
                                              favicon: { width: 100, height: 100 }
                                            } // Max dimensions
                                          )}
                                          accept="image/jpeg, image/jpg, image/png"
                                        name='Favicon'
                                    />
                                    <div className='text-danger mt-2'>
                                        Recommended size: 100 x 100 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
                                    </div>

                                    {selectedOrganisation.favicon && (
                                        <div className='mt-2'>
                                            <img
                                                src={selectedOrganisation.favicon}
                                                alt='favicon'
                                                className="img-fluid edit-organisation-img-icon bg-dark rounded"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>White Logo</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='file'
                                        className='form-control form-control-lg form-control-solid'
                                        onChange={(e) => handleImageChange(
                                            e,
                                            'white_logo',
                                            2, // Max file size (2MB)
                                            ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                                            {
                                                white_logo: { width: 400, height: 400 }
                                            } // Max dimensions
                                          )}
                                          accept="image/jpeg, image/jpg, image/png"
                                        name='picture'
                                    />
                                    <div className='text-danger mt-2'>
                                        Recommended size: 400 x 400 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
                                    </div>
                                    {selectedOrganisation.white_logo && (
                                        <div className='mt-2'>
                                            <img
                                                src={selectedOrganisation.white_logo}
                                                alt="White Logo"
                                                className="img-fluid edit-organisation-img bg-dark rounded"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Coloured Logo</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='file'
                                        className='form-control form-control-lg form-control-solid'
                                        onChange={(e) => handleImageChange(
                                            e,
                                            'color_logo',
                                            2, // Max file size (2MB)
                                            ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                                            {
                                                color_logo: { width: 400, height: 400 }
                                            } // Max dimensions
                                          )}
                                          accept="image/jpeg, image/jpg, image/png"
                                          name='Coloured Logo'
                                    />
                                    <div className='text-danger mt-2'>
                                        Recommended size: 400 x 400 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
                                    </div>
                                    {selectedOrganisation.color_logo && (
                                        <div className='mt-2'>
                                            <img
                                                src={selectedOrganisation.color_logo}
                                                alt='Color Logo'
                                                className="img-fluid edit-organisation-img bg-dark rounded"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="card-footer d-flex flex-column align-items-end py-6 px-9">
                    {nameError ? (
                        <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update Organisation" className='btn btn-primary disabled'>
                            Update Organisation
                        </button>
                    ) : (
                        <button
                            data-toggle="tooltip" data-placement="bottom" title="Update Organisation"
                            type='button'
                            className='btn btn-primary'
                            onClick={handleUpdateOrganisation}
                            disabled={loading}
                        >
                            {!loading && 'Update Organisation'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    )}
                    <p className="text-danger mt-2">
                        The changes in the settings will be effected on next login.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default EditOrganisation