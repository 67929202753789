import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { get_user_profile, update_profile } from './api/profileApi';
import { useNavigate } from 'react-router-dom';
import { config_file_size } from '../../../_metronic/helpers/config/config';
import { validateImage } from '../../helper_functions/ImageValidation';
const ProfileHeader: React.FC = () => {
  const { data: ViewProfileDetails, isLoading: viewIsLoading, error: viewIsError, refetch } = useQuery(
    'ViewProfileDetails',
    get_user_profile,
    { refetchOnWindowFocus: false }
  );

  const [userPicture, setUserPicture] = useState<File | null>(null);
  const navigate = useNavigate();

  const updateProfileMutation = useMutation(
    async (values: any) => {
      const formData = new FormData();

      // Append form fields
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Append the user picture if available
      if (userPicture) {
        formData.append('profile_picture', userPicture);
      }

      // API call
      return update_profile(formData);
    },
    {
      onSuccess: (response) => {
        refetch();
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
      },
      onError: (error: any) => {
        const message = error.response?.data?.message || 'An unexpected error occurred.';
        toast.error(message, {
          position: 'top-right',
          autoClose: 3000,
        });
      },
    }
  );

  // Validation schema
  const validationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required.'),
    last_name: Yup.string().required('Last Name is required.'),
    mobile_no: Yup.string()
      .matches(/^[+0-9]*$/, 'Mobile Number must be numeric.')
      .nullable(),
  });

  // Formik initialization
  const formik = useFormik({
    initialValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      mobile_no: '',
    },
    validationSchema,
    onSubmit: (values) => {
      updateProfileMutation.mutate(values);
    },
  });

  // Populate form fields with fetched data
  useEffect(() => {
    if (ViewProfileDetails?.data) {
      formik.setValues({
        first_name: ViewProfileDetails.data.first_name || '',
        middle_name: ViewProfileDetails.data.middle_name || '',
        last_name: ViewProfileDetails.data.last_name || '',
        mobile_no: ViewProfileDetails.data.mobile_no || '',
      });
    }
  }, [ViewProfileDetails]);

  const handleImageChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      logoType: string,
      MAX_FILE_SIZE: number,
      ALLOWED_TYPES: string[],
      MAX_DIMENSIONS: { [key: string]: { width: number; height: number } }
    ) => {
      const newFile = e.target.files?.[0];
      if (newFile) {
        // Call the validation function
        validateImage(
          newFile,
          logoType,
          (validFileUrl) => {
            // If valid, update the state with the image URL
            if (logoType === 'picture') {
              setUserPicture(newFile);
            }
          },
          MAX_FILE_SIZE, // File size limit in MB
          ALLOWED_TYPES, // Allowed file types
          MAX_DIMENSIONS // Maximum dimensions per logo type
        );
      }
    };
  if (viewIsLoading) {
    return (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (viewIsError) {
    return (
      <div className="text-center">
        <h2>Error fetching profile details</h2>
      </div>
    );
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header text-center border-0 pt-4 pb-4 bg-light">
        <button
          className="btn btn-sm btn-light-dark fs-5"
          onClick={() => navigate('/organisations/organisations-list')}
        >
          Return to Organisations List
        </button>
      </div>
      <div className="card-body pt-9 pb-0">
        <form onSubmit={formik.handleSubmit}>
          {/* First Name */}
          <div className="row mb-6">
            <label className="col-lg-3 col-form-label required fw-bold fs-6">First Name</label>
            <div className="col-lg-9">
              <input
                type="text"
                className={`form-control form-control-lg form-control-solid ${formik.touched.first_name && formik.errors.first_name ? 'is-invalid' : ''}`}
                placeholder="First Name"
                {...formik.getFieldProps('first_name')}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <div className="text-danger">{formik.errors.first_name}</div>
              )}
            </div>
          </div>

          {/* Middle Name */}
          <div className="row mb-6">
            <label className="col-lg-3 col-form-label fw-bold fs-6">Middle Name</label>
            <div className="col-lg-9">
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                placeholder="Middle Name"
                {...formik.getFieldProps('middle_name')}
              />
            </div>
          </div>

          {/* Last Name */}
          <div className="row mb-6">
            <label className="col-lg-3 col-form-label required fw-bold fs-6">Last Name</label>
            <div className="col-lg-9">
              <input
                type="text"
                className={`form-control form-control-lg form-control-solid ${formik.touched.last_name && formik.errors.last_name ? 'is-invalid' : ''}`}
                placeholder="Last Name"
                {...formik.getFieldProps('last_name')}
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <div className="text-danger">{formik.errors.last_name}</div>
              )}
            </div>
          </div>

          {/* Mobile Number */}
          <div className="row mb-6">
            <label className="col-lg-3 col-form-label fw-bold fs-6">Mobile Number</label>
            <div className="col-lg-9">
              <input
                type="tel"
                className={`form-control form-control-lg form-control-solid ${formik.touched.mobile_no && formik.errors.mobile_no ? 'is-invalid' : ''}`}
                placeholder="Mobile Number"
                {...formik.getFieldProps('mobile_no')}
              />
              {formik.touched.mobile_no && formik.errors.mobile_no && (
                <div className="text-danger">{formik.errors.mobile_no}</div>
              )}
            </div>
          </div>
          {/* Picture Upload */}
          <div className="row mb-6">
            <label className="col-lg-3 col-form-label fw-bold fs-6">Picture</label>
            <div className="col-lg-9 fv-row">
              <input
                type="file"
                className="form-control form-control-lg form-control-solid"
                onChange={(e) => handleImageChange(
                  e,
                  'picture',
                  2, // Max file size (2MB)
                  ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                  {
                    picture: { width: 200, height: 200 }
                  } // Max dimensions
                )}
                accept="image/jpeg, image/jpg, image/png"
                name='picture'
              />
              <div className='text-danger mx-6 mt-2'>
                Recommended size: 200 x 200 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
              </div>
            </div>
          </div>
          {/* Existing Picture */}
          <div className="row mb-6">
            <label className="col-lg-3 col-form-label fw-bold fs-6">Existing Picture</label>
            <div className="col-lg-9 d-flex align-items-center">
              {ViewProfileDetails?.data?.profile_picture ? (
                <img
                  src={ViewProfileDetails?.data?.profile_picture}
                  alt="profile picture"
                  className="img-fluid cover"
                  width={100}
                  height={100}
                  aria-readonly
                />
              ) : (
                <span className="text-muted mx-6 mb-1">No picture uploaded yet</span>
              )}
            </div>
          </div>
          {/* Submit Button */}
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary" disabled={updateProfileMutation.isLoading}>
              {updateProfileMutation.isLoading ? (
                <span>
                  Please wait...{' '}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                'Update Profile'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { ProfileHeader };
