import clsx from 'clsx';
import { KTIcon } from '../../../helpers';
import { ThemeModeComponent } from '../../../assets/ts/layout';
import { ThemeModeType, useThemeMode } from './ThemeModeProvider';
import { useEffect } from 'react';

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
};

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark';

const ThemeModeSwitcher = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const { mode, menuMode, updateMode, updateMenuMode } = useThemeMode();

  // Get mode from localStorage or fallback to systemMode
  const savedMode = localStorage.getItem('kt_theme_mode_value') as ThemeModeType;
  const calculatedMode = savedMode || (mode === 'system' ? systemMode : mode);

  const switchMode = (_mode: ThemeModeType) => {
    // Update state
    updateMenuMode(_mode);
    updateMode(_mode);

    // Save selected theme mode to localStorage
    localStorage.setItem('kt_theme_mode_value', _mode);

    // Apply the theme immediately by updating the HTML root attribute
    document.documentElement.setAttribute('data-bs-theme', _mode);
  };

  // Apply the saved mode on component mount
useEffect(() => {
    if (savedMode) {
      document.documentElement.setAttribute('data-bs-theme', savedMode);
    }
  }, [savedMode]);

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href="#"
        className={clsx('btn btn-icon', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach="parent"
        data-kt-menu-placement={menuPlacement}
      >
        {calculatedMode === 'dark' && (
          <KTIcon iconName="moon" className={clsx('theme-light-hide', toggleBtnIconClass)} />
        )}
        {calculatedMode === 'light' && (
          <KTIcon iconName="night-day" className={clsx('theme-dark-hide', toggleBtnIconClass)} />
        )}
      </a>
      {/* end::Menu toggle */}

      {/* begin::Menu */}
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
        data-kt-menu="true"
      >
        {/* Light Mode */}
        <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={clsx('menu-link px-3 py-2', { active: calculatedMode === 'light' })}
            onClick={() => switchMode('light')}
          >
            <span className="menu-icon" data-kt-element="icon">
              <KTIcon iconName="night-day" className="fs-1" />
            </span>
            <span className="menu-title">Light</span>
          </a>
        </div>

        {/* Dark Mode */}
        <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={clsx('menu-link px-3 py-2', { active: calculatedMode === 'dark' })}
            onClick={() => switchMode('dark')}
          >
            <span className="menu-icon" data-kt-element="icon">
              <KTIcon iconName="moon" className="fs-1" />
            </span>
            <span className="menu-title">Dark</span>
          </a>
        </div>

        {/* System Mode */}
        {/* <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={clsx('menu-link px-3 py-2', { active: savedMode === 'system' || mode === 'system' })}
            onClick={() => switchMode('system')}
          >
            <span className="menu-icon" data-kt-element="icon">
              <KTIcon iconName="screen" className="fs-1" />
            </span>
            <span className="menu-title">System</span>
          </a>
        </div> */}
      </div>
      {/* end::Menu */}
    </>
  );
};

export { ThemeModeSwitcher };
