import { useEffect , useState } from 'react'
import { OrganisationListInterface } from '../../../modules/accounts/components/settings/SettingsModel'
import { useQuery } from 'react-query'
import { get_all_organisations, get_organisation_by_user } from '../api/OrganisationsApi'
import { Card } from 'react-bootstrap'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'

function OrganisationsList() {
    const [organisationsList, setOrganisationsList] = useState<OrganisationListInterface[]>([])
    const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationListInterface>() 
    const [selectedOrganisationUser, setSelectedOrganisationUser] = useState<any | null>();
    const authData = JSON.parse(localStorage.getItem('kt-auth-react-v') || '{}');
    const [canManageOrganisation,setCanManageOrganisation] = useState(false);
    const navigate = useNavigate()
    const { data: organisationsData,isLoading} = useQuery('organisations', get_all_organisations, {
        refetchOnWindowFocus: false,
    })
    const current_user_id=authData.user.id;
    const { data:dataSingleUser,refetch:dataSingleUserRefetch } = useQuery(['get_organisation_by_user',current_user_id,{ refetchOnMount: true, 
        refetchOnWindowFocus: true,  }], () =>
        get_organisation_by_user(current_user_id)
    );
    // console.log("dataSingleUser",dataSingleUser.data.can_manage_application)
    // const iframeRef = useRef<HTMLIFrameElement | null>(null);
    // useEffect(() => {
    //     const iframe = iframeRef.current;

    //     // Check if iframe is loaded
    //     const handleLoad = () => {
    //         // Parse user from localStorage
    //         const user: User | null = JSON.parse(localStorage.getItem('kt-auth-react-v') || '{}');

    //         // Get token from user object
    //         const token = user?.access_token;

    //         console.log('Token:', token);  // Debugging purpose

    //         // Post token to iframe if exists
    //         if (token && iframe && iframe.contentWindow) {
    //             iframe.contentWindow.postMessage({ token: token }, 'https://multitenant.wrikooffice.staginghosts.com');
    //         } else {
    //             console.warn('Token not available or iframe not ready');
    //         }
    //     };

    //     if (iframe) {
    //         iframe.onload = handleLoad;
    //     }

    //     // Cleanup function
    //     return () => {
    //         if (iframe) {
    //             iframe.onload = null; // Remove the event listener on unmount
    //         }
    //     };
    // }, []);

    useEffect(() => {
        if (organisationsData) {
            setOrganisationsList(organisationsData.data as OrganisationListInterface[])
        }
    }, [organisationsData])
    // const handleDeleteOrganisation = useMutation((id: number) => {
    //     return delete_organisation(id)
    //         .then((response) => {
    //             toast.success(response.message, {
    //                 position: 'top-right',
    //                 autoClose: 3000,
    //             })
    //         })
    //         .then(() => {
    //             return get_all_organisations().then((data) => {
    //                 setOrganisationsList(data.data);
    //             })
    //         })
    //         .catch((error: any) => {
    //             if (error.response) {
    //                 const errorMessage = error.response.data.message;
    //                 toast.error(errorMessage, {
    //                     position: 'top-right',
    //                     autoClose: 2000,
    //                 })
    //             }
    //         });
    // });
    const handleViewApplications = (organisation: any) => {
        localStorage.setItem("current_organisation_id",organisation.id)
        localStorage.setItem("current_organisation_name",organisation.name)
        navigate("/organisations/applications", { state: { organisation: organisation } });
    };
    const handleOrganisationEdit = (organisation: any) => {
        localStorage.setItem("current_organisation_id",organisation.id)
        localStorage.setItem("current_organisation_name",organisation.name)
        navigate("/organisations/edit-organisation", { state: { organisation: organisation } });
    };
    const handleOrganisationUsers = (organisation: any) => {
        localStorage.setItem("current_organisation_id",organisation.id)
        localStorage.setItem("current_organisation_name",organisation.name)
        navigate("/organisations/organisation-users", { state: { organisation: organisation } });
    };
    if (isLoading) {
        return (
            <div className='text-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        );
      }
      const storedTheme = localStorage.getItem('kt_theme_mode_value');
    return (
        <>
            <div className='container-fluid'>
                <h1 className='fw-bolder m-0 mb-4'>My Organisations</h1>
                <div className="organisation-container mt-3">
                    {organisationsList.map((organisation, index) => {
                        const canManage = dataSingleUser?.data?.some(
                            (userOrg) =>
                                userOrg.organization_id == organisation.id &&
                                userOrg.can_manage_organization == 1
                        );

                        return (
                            <Card
                                key={index}
                                className="organisation-card bg-light"
                                style={{ width: 'fit-content' }}
                                onClick={() => handleViewApplications(organisation)}
                            >
                                <Card.Body className="d-flex flex-column">
                                    <div className="d-flex justify-content-center mb-2">
                                        {organisation?.favicon === null ? (
                                            <img
                                                alt="Logo"
                                                src={
                                                    storedTheme === "dark"
                                                        ? toAbsoluteUrl("/media/logos/wriko_central_white_icon.png")
                                                        : toAbsoluteUrl("/media/logos/wriko_central_icon.png")
                                                }
                                                className="h-25px"
                                            />
                                        ) : (
                                            <img
                                                alt="Logo"
                                                src={
                                                    storedTheme === "dark"
                                                        ? organisation?.white_logo
                                                        : organisation?.color_logo
                                                }
                                                className="h-25px"
                                            />
                                        )}
                                    </div>
                                    <Card.Title className="organisation-card-title text-primary cursor-pointer text-dark">
                                        {organisation.name}
                                    </Card.Title>

                                    {canManage && (
                                        <div className="d-flex justify-content-center mt-auto organisation-setting-button">
                                            <button
                                                data-toggle="tooltip"
                                                title="Edit Organisation"
                                                className="btn btn-icon btn-sm btn-active-secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOrganisationEdit(organisation);
                                                }}
                                            >
                                                <KTIcon iconName="setting-2" className="fs-3 text-dark" />
                                            </button>
                                            <button
                                                data-toggle="tooltip"
                                                title="Edit Organisation User"
                                                className="btn btn-icon btn-sm btn-active-secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOrganisationUsers(organisation);
                                                }}
                                            >
                                                <KTIcon iconName="user" className="fs-3 text-dark" />
                                            </button>
                                        </div>
                                    )}
                                </Card.Body>

                            </Card>
                        );
                    })}

                    {/* Create New Organisation Card */}
        <Card className="organisation-card bg-light">
            <Card.Body className="d-flex justify-content-center align-items-center flex-column">
                <button
                    className="btn btn-icon btn-dark btn-active-secondary btn-md round-button"
                    onClick={() => navigate("/organisations/create-organisation")}
                >
                    <KTIcon iconName="plus" className="fs-3" />
                </button>
                <Card.Title className="text-primary cursor-pointer fs-8 text-dark mt-1">
                    Create new Organisation
                </Card.Title>
            </Card.Body>
        </Card>
    </div>
</div>
            {/* Delete modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Delete User Confirmation</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedOrganisation ? (
                                <>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>User Name: </span> {selectedOrganisation.name}
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Emails: </span>{' '}
                                        {selectedOrganisation.email}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Organisation"
                                type='button'
                                // onClick={() => {
                                //     if (selectedOrganisation && selectedOrganisation.id) {
                                //         handleDeleteOrganisation.mutate(selectedOrganisation.id)
                                //     }
                                // }}
                                className='btn btn-danger'
                                data-bs-dismiss='modal'
                            >
                                Delete Organisation
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrganisationsList